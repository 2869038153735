<template>
  <div>
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Technographics</b> - <i>Vendor Request</i>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col sm="6">
            <h5 class="mb-0">Vendor Request</h5>
          </b-col>
          <b-col sm="6" class="text-right">
            <h6 class="mb-0">Please complete the form below.</h6>
          </b-col>
        </b-row>
        <hr class="style-one mt-1" />
        <b-row class="mx-auto justify-content-center">
          <b-col sm="5">
            <b-card @submit.prevent="createRequest()">
              <form>
                <b-form-group
                  id="vendor_name"
                  label="Vendor Name:"
                  label-for="vendor_name"
                  :label-cols="4"
                  class="mb-0"
                  :horizontal="true"
                >
                  <input
                    id="vendor_name"
                    class="col-sm-10"
                    type="text"
                    v-model="vendorName"
                    @keydown.enter="addVendor($event)"
                    placeholder="Type example vendor providing solutions"
                    autocomplete="off"
                    :class="[
                      'form-control',
                      {
                        'is-invalid': $v.requests.vendor_names.$error,
                        'is-valid': !$v.requests.vendor_names.$invalid
                      }
                    ]"
                    @blur="$v.requests.vendor_names.$touch()"
                  />
                  <b-button
                    class="ml-1"
                    variant="primary"
                    @click="addVendor()"
                    :disabled="enableAdd"
                    >Add</b-button
                  >
                </b-form-group>
                <b-row class="mb-2">
                  <b-col cols="4"></b-col>
                  <b-col cols="6 pl-2">
                    <b-form-invalid-feedback
                      :class="{
                        'vendor-name-error': $v.requests.vendor_names.$error
                      }"
                      >can't be blank</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback
                      :class="{
                        'vendor-name-error': !$v.vendorName.shouldBeUniq
                      }"
                      >duplicate vendor name</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-group
                  label="Requested Vendors:"
                  label-for="example_vendors"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <b-list-group id="example_vendors">
                    <b-list-group-item
                      v-for="vendor in requests.vendor_names"
                      :key="vendor"
                    >
                      <i
                        class="icon-close icons font-l delete-vendor pr-1"
                        @click="deleteVendor(vendor)"
                      ></i>
                      {{ vendor }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <label><h6>Your contact information</h6></label>
                <b-form-group
                  label="Name:"
                  label-for="name"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="requests.name"
                    placeholder="Full Name"
                    :class="{
                      'is-invalid': $v.requests.name.$error,
                      'is-valid': !$v.requests.name.$invalid
                    }"
                    @blur.native="$v.requests.name.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.requests.name.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  label="Company:"
                  label-for="company"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <b-form-input
                    id="company"
                    type="text"
                    v-model="requests.company"
                    placeholder="Company"
                    :class="{
                      'is-invalid': $v.requests.company.$error,
                      'is-valid': !$v.requests.company.$invalid
                    }"
                    @blur.native="$v.requests.company.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.requests.company.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  label="Email:"
                  label-for="email"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <b-form-input
                    id="email"
                    type="email"
                    v-model="requests.email"
                    placeholder="Email Address"
                    :class="{
                      'is-invalid': $v.requests.email.$error,
                      'is-valid': !$v.requests.email.$invalid
                    }"
                    @blur.native="$v.requests.email.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.requests.email.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.requests.email.email">
                    Please provide a valid email
                    address</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-row class="justify-content-center mt-5">
                  <b-button
                    type="submit"
                    variant="success"
                    class="fw-btn"
                    :disabled="$v.$invalid"
                    >Send Request</b-button
                  >
                </b-row>
                <b-modal
                  ref="successModal"
                  :hide-footer="true"
                  :hide-header="true"
                >
                  <b-row class="text-center">
                    <b-col sm="12">
                      <h6>Thank you for your request.</h6>
                      <b-button
                        variant="success"
                        @click="closeModal"
                        class="mt-3"
                        >Close</b-button
                      >
                    </b-col>
                  </b-row>
                </b-modal>
              </form>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script type="text/javascript">
import router from '@/router'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.currentUserEmail) {
        vm.$store.dispatch('setRedirectUrl', vm.$route.fullPath)
        router.push('/sign_in')
      }
    })
  },
  beforeCreate() {
    document.title = 'FIN Technographics'
  },
  data() {
    return {
      vendorName: '',
      requests: {
        vendor_names: [],
        name: null,
        company: null,
        email: null
      }
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    enableAdd() {
      return this.vendorName.length < 1
    }
  },
  methods: {
    addVendor(e) {
      if (typeof e !== 'undefined') {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
      if (this.$v.vendorName.shouldBeUniq) {
        this.requests.vendor_names.push(this.vendorName)
        this.vendorName = ''
      }
      return false
    },
    deleteVendor(vendorName) {
      let index = this.requests.vendor_names.indexOf(vendorName)
      this.requests.vendor_names.splice(index, 1)
    },
    createRequest() {
      this.$http
        .post('/api/vendor_requests', {
          handleErrors: true,
          requests: this.requests
        })
        .then(() => {
          this.$refs.successModal.show()
        })
    },
    closeModal() {
      this.$refs.successModal.hide()
      router.push({ name: 'product-search' })
    }
  },
  validations: {
    vendorName: {
      shouldBeUniq(val) {
        let duplicateVendor = this.requests.vendor_names.find(
          vendor => vendor.toLocaleLowerCase() === val.toLocaleLowerCase()
        )
        return duplicateVendor === undefined
      }
    },
    requests: {
      vendor_names: {
        required
      },
      name: {
        required
      },
      company: {
        required
      },
      email: {
        required,
        email
      }
    }
  }
}
</script>

<style type="scss" scoped>
.delete-vendor {
  cursor: pointer;
}

.vendor-name-error {
  display: block;
}
</style>
